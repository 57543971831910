import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import TeamStyleOne from '../components/Team/TeamStyleOne'
 
const Team = () => {
    return (
        <Layout
            titulo="Nuestro equipo"
            descripcion="El equipo de programadores de Do{main}"
            slug="team"
            imagen="https://res.cloudinary.com/dev-empty/image/upload/v1630842327/how_its_work_7084f78ed9.png"
        >
            <Navbar />

            <PageBanner
                pageTitle="Nuestro Equipo" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Equipo" 
            />

            <TeamStyleOne />
            
            <Footer />
        </Layout>
    );
}

export default Team